import React, { useEffect, useRef, useState, useContext } from 'react'

import styled from 'styled-components'
import { media } from 'utils/media'

import { useForm } from 'react-hook-form'

import ModalContext from 'context/ModalContext'
import StepOne from 'components/Modals/NewSignup/StepTwo'
import StepTwo from 'components/Modals/NewSignup/StepOne'
import { StaticImage } from 'gatsby-plugin-image'
import { Layout } from 'components/global'

import CloseIcon from 'svgs/cross-white.svg'
import { Link, navigate } from 'gatsby'

const SignupStyles = styled.section`
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  z-index: 99999999999;
  /* background: var(--blue); */
  background: rgba(10, 22, 57, 0.5);
  backdrop-filter: blur(25px);
  overflow-y: scroll;
  @media ${media.md} {
    align-items: center;
    justify-content: center;
  }
  .background {
    position: relative;
    max-width: 1440px;
    height: 100%;
    max-height: 900px;
    .main-background {
      position: absolute;
      inset: 0;
      width: 100%;
      height: 100vh;
      content: '';
      background: var(--darkGrey);
      z-index: 5;
      max-height: 900px;
    }
  }
  .close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 20px;
    height: 20px;
    fill: var(--white) !important;
    z-index: 9999999;
    cursor: pointer;
  }
  .wrapper {
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
    z-index: 10;
    overflow: scroll;
    padding: 3rem 0;
    width: 100%;
    height: 100%;

    @media ${media.ls} {
      display: flex;
      overflow-y: scroll;
      flex-direction: column;
      justify-content: center;
    }
    @media ${media.md} {
      height: 100%;
      padding: 0;
    }
    .img {
      display: none;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 5rem;
      .gatsby-image-wrapper {
        width: 100%;
        height: 100%;
      }
    }

    .question {
      strong {
        @media ${media.ls} {
          font-size: 1.1rem;
          line-height: 1.1rem;
        }
        @media ${media.md} {
          margin-top: 1rem;
          font-size: 1.25rem;
        }
        span {
          color: var(--yellow);
        }
      }
      ul {
        margin: 1rem 0;
        display: flex;
        flex-direction: column;
        li {
          font-size: 1rem;
          display: flex;
          align-items: center;
          gap: 1rem;
          input[type='radio'] {
            padding: 0;
            margin: 0;
            width: auto;
          }
          p {
            margin-bottom: 0;
            padding-bottom: 0;
          }
          &:nth-child(1) {
            span {
              color: var(--teal);
              font-weight: 700;
            }
          }
          &:nth-child(2) {
            span {
              color: var(--yellow);
              font-weight: 700;
            }
          }
          &:nth-child(3) {
            span {
              color: var(--pink);
              font-weight: 700;
            }
          }
        }
      }
    }
    .form {
      padding: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 85%;
      /* height: 100%; */
      margin: var(--auto);
      h3 {
        font-size: 1.5rem;
        line-height: 1.5rem;
        margin-bottom: 0.5rem;
        @media ${media.ls} {
          font-size: 1.8rem;
          line-height: 1.8rem;
        }
        @media ${media.md} {
          font-size: 2rem;
          line-height: 2.2rem;
        }
        @media ${media.xl} {
          line-height: 2.5rem;
        }
      }
      .text {
        p {
          margin-bottom: 0.5rem;
        }
      }
      ul {
        list-style: none;
      }
      .hidden {
        display: none;
      }
      p,
      li {
        font-size: 0.95rem;
        margin-bottom: 0.5rem;
      }
      form {
        margin-top: 1rem;
        section {
          display: flex;
          flex-direction: column;
          /* gap: 0.5rem; */
          @media ${media.md} {
            flex-direction: row;
            gap: 1rem;
          }
        }
        input {
          width: 100%;
          display: block;
          background: var(--white);
          padding: 0.85rem;
          border: none;
          font-size: 0.85rem;
          margin-bottom: 0.75rem;
          outline: none;
          font-family: var(--Gotham);
          color: var(--darkGrey);
          font-weight: 700;
          &::placeholder {
            font-weight: 800;
            font-size: 0.85rem;
            font-family: var(--Gotham);
            color: var(--darkGrey);
          }
        }
        .buttons {
          @media ${media.md} {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 2rem;
            button {
              max-width: 250px;
            }
          }
        }
        .btn-alt {
          background: var(--pink);
          span {
            color: var(--white);
          }
          &:hover,
          &:active {
            background: var(--yellow);
            span {
              color: var(--black);
            }
          }
        }
        button {
          font-family: var(--Gotham);
          padding: 1rem 0.85rem;
          margin-top: 1rem;
          width: 100%;
          max-width: 100%;
          span {
            font-size: 1rem;
            font-weight: 800;
          }
        }
        > p {
          color: var(--white);
          margin-top: 1rem;
          font-size: 0.8rem;
          font-family: var(--Fantabular);
          a {
            color: var(--mint);
            text-decoration: underline;
            transition: color 0.25s ease-in-out;
            will-change: color;
            &:hover,
            &:active {
              color: var(--pink);
            }
          }
        }
        .legal {
          color: var(--white);
          font-size: 0.65rem;
          text-align: left;
          margin-bottom: 0.5rem;
          a {
            color: var(--teal);
            font-weight: 400;
          }
        }
        .checkboxes {
          display: flex;
          input {
            width: 20px;
            margin-right: 5px;
          }
          p {
            font-size: 0.65rem;
            color: var(--white);
            text-align: left;
            padding-left: 5px;
            a {
              color: var(--yellow);
              font-weight: 400;
            }
          }
        }
        @media ${media.ls} {
          .legal,
          .checkboxes {
            font-size: 0.75rem;
            p {
              font-size: 0.75rem;
            }
          }
        }
      }
    }
    @media ${media.md} {
      display: grid;
      grid-template-columns: 1fr 50%;
      .img {
        position: static;
        height: 100%;
        width: 100%;
        display: block;
        max-height: 100vh;
        .gatsby-image-wrapper {
          width: 100%;
          height: 100%;
          max-height: 900px;
        }
      }
      .form {
      }
    }
    @media ${media.lg} {
      grid-template-columns: 1fr 50%;
    }
    @media ${media.xl} {
      grid-template-columns: 1fr 55%;
      .form {
        padding: 2rem;
        h3 {
          margin-bottom: 1rem;
          font-size: 2.25rem;
        }
        form {
          margin-top: 0.5rem;
          > section {
            gap: 0.85rem;
          }
          input {
            margin-bottom: 0.85rem;
            font-size: 0.9rem;
            &::placeholder {
              font-size: 0.9rem;
            }
            /* margin-bottom: 2rem; */
          }
        }
      }
    }
  }
`

const EnterNow = () => {
  const { visitedFirstTime, setVisitedFirstTime } = useContext(ModalContext)
  const handleClose = () => {
    setVisitedFirstTime(true)
    navigate('/')
  }
  const [step, setStep] = useState(0)
  const [formData, setFormData] = useState({
    firstName: '',
    secondName: '',
    email: '',
    tel: '',
    answer: '',
    optIn: '',
  })

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    mode: 'onSubmit',
  })

  return (
    <Layout>
      <SignupStyles>
        <div className="background">
          <div className="main-background" />
          <CloseIcon className="close" onClick={() => handleClose()} />
          <div className="wrapper">
            <div className="form">
              <h3>ENTER NOW FOR THE CHANCE TO WIN</h3>
              <section className={`text ${step !== 0 && 'hidden'}`}>
                <p>
                  <em>
                    Get ready&hellip; the European champions will be back in
                    action February 2023 to defend the Arnold Clark Cup
                    <span>!</span>&nbsp;
                  </em>
                </p>
                <p>
                  <em>
                    And you could really be looking the part. Answer the
                    question below and go in the draw to win a signed playing
                    shirt from each of the 2022 Arnold Clark Cup teams.&nbsp;
                  </em>
                </p>
                <p>
                  <em>
                    England. Germany. Spain. Canada. Four signed shirts, all of
                    them yours.
                  </em>
                </p>
              </section>
              {step === 0 ? (
                <StepOne
                  step={step}
                  setStep={setStep}
                  formData={formData}
                  setFormData={setFormData}
                  setOpen={false}
                />
              ) : (
                ''
              )}
              {step === 1 ? (
                <StepTwo
                  step={step}
                  setStep={setStep}
                  formData={formData}
                  setFormData={setFormData}
                  setOpen={false}
                />
              ) : (
                ''
              )}
              {/* {step === 1 && <StepOne />} */}
            </div>
            <div className="img">
              <StaticImage src="../../../images/new-sign-up.jpeg" alt="" />
            </div>
          </div>
        </div>
      </SignupStyles>
    </Layout>
  )
}

export default EnterNow
